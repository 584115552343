<template>
  <div 
    id="acces"
  >
    <Menu/>
    <b-container>
      <b-row
        class="mb-5 hiddenSmallScreen"
      >
        <b-col lg="6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.8508127130767!2d4.0702673157299625!3d43.651272160572894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b69f59deb36427%3A0xe252fa25065959bd!2sMairie!5e0!3m2!1sfr!2sfr!4v1644497096598!5m2!1sfr!2sfr" width="100%" height="338" style="border:0;border-radius: 15px;" allowfullscreen="" loading="lazy"></iframe>
        </b-col>
        <b-col 
          lg="6" 
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <p>Retrouve-nous le samedi 4 juin 2022, à 15h</p>
          <p>à la mairie de Lansargues</p>
          <p>Place Saint-Jean, 34130 Lansargues</p>
          <p class="h1 mb-2"><b-icon-arrow-return-left></b-icon-arrow-return-left></p>
        </b-col>
      </b-row>
      <b-row
        class="mb-5 hiddenLargeScreen"
      >
        <b-col 
          lg="6" 
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <p>Retrouve-nous le samedi 4 juin 2022, à 15h</p>
          <p>à la mairie de Lansargues</p>
          <p>Place Saint-Jean, 34130 Lansargues</p>
        </b-col>
        <b-col lg="6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.8508127130767!2d4.0702673157299625!3d43.651272160572894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b69f59deb36427%3A0xe252fa25065959bd!2sMairie!5e0!3m2!1sfr!2sfr!4v1644497096598!5m2!1sfr!2sfr" width="100%" height="338" style="border:0;border-radius: 15px;" allowfullscreen="" loading="lazy"></iframe>
        </b-col>
      </b-row>
      <b-row>
        <b-col 
          lg="6"
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <p>Retrouve-nous le samedi 4 juin 2022, à 18h</p>
          <p>au Mas de Remoulis</p>
          <p>30440 Saint-Julien-de-la-Nef</p>
          <p class="h1 mb-2 hiddenSmallScreen"><b-icon-arrow-return-right></b-icon-arrow-return-right></p>
        </b-col>
        <b-col lg="6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4829.963874652779!2d3.6823876379127283!3d43.96115076847626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b3f4205dc921b7%3A0x295bdb9d85e2e9d5!2sR%C3%A9moulis%2C%2030440%20Saint-Julien-de-la-Nef!5e0!3m2!1sfr!2sfr!4v1644497422244!5m2!1sfr!2sfr" width="100%" height="338" style="border:0;border-radius: 15px;" allowfullscreen="" loading="lazy"></iframe>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Menu from './../components/Menu.vue'
export default {
  components: {
    Menu
  },
}
</script>
<style>
.hiddenLargeScreen {
  display: none !important;
}
@media (max-width: 980px) {
  .hiddenSmallScreen {
    display: none !important;
  }
  .hiddenLargeScreen {
    display: block !important;
  }
}
</style>
